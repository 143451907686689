import * as React from 'react'
import { Box } from '@chakra-ui/react'
import styled, { css } from 'styled-components'
import fontSizes from 'theme/foundations/fontSizes'
import { Label } from 'components/Label'
import {CustomTextEditor, Props as CustomTextEditorProps} from 'components/CustomTextEditor'

const commonCss = css`
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  h1 {
    font-size: ${fontSizes['5xl']};
  }

  h2 {
    font-size: ${fontSizes['4xl']};
  }

  h3 {
    font-size: ${fontSizes['3xl']};
  }

  h4 {
    font-size: ${fontSizes['2xl']};
  }

  h5 {
    font-size: ${fontSizes.xl};
  }

  h5 {
    font-size: ${fontSizes.lg};
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
`

const Wrapper = styled(Box)`
  &,
  * {
    outline: none;
  }
  .ProseMirror p.is-editor-empty:first-child::before {
    color: #adb5bd;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }
  /* Basic editor styles */
  .ProseMirror {
    ${commonCss}
  }
`

interface Props extends CustomTextEditorProps {
  label?: string
}

export const SimpleTextEditor: React.FC<Props> = ({ label, initialContent, onChangeContent }) => (
  <Box>
    {label && <Label>{label}</Label>}
    <Box border="1px solid" borderColor="teal.200" p="2" borderRadius="lg">
      <CustomTextEditor initialContent={initialContent} onChangeContent={onChangeContent} />
    </Box>
  </Box>
)
