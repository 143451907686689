import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { ProfileQueryResult } from 'src/generated/graphql-frontend'
import { Layout, HashTabs } from './components'
import { AboutTab, MembershipsTab, PostsTab, ShopTab } from './tabs'
import {getRoute} from 'utils/helpers'

interface Props {
  profile: NonNullable<NonNullable<ProfileQueryResult['data']>['profile']>
  defaultTabKey?: string
  defaultDonationAmount?: number
  aboveContentComp?: React.ReactNode
}

export const ProfilePage: React.FC<Props> = ({
  profile,
  defaultTabKey,
  defaultDonationAmount,
  aboveContentComp,
}) => {
  const { t } = useTranslation('username')
  const hasTiers = (profile.Tiers?.length || 0) > 0
  const { hasShopProducts } = profile
  const hashTabs = React.useMemo<HashTabs>(
    () => [
      { key: 'about', label: t('profilePageTabAbout'), hasTabsWrapper: true },
      { key: 'posts', label: t('profilePageTabPosts'), hasTabsWrapper: false },
      hasShopProducts ? { key: 'shop', label: t('profilePageTabShop'), isFullScreen: true } : undefined,
      { key: 'memberships', label: t('profilePageTabMemberships'), isFullScreen: true, isHidden: !hasTiers },
    ].filter(Boolean) as HashTabs,
    [hasTiers]
  )
  return (
    <Layout
      key={defaultTabKey}
      hashTabs={hashTabs}
      defaultTabKey={defaultTabKey}
      profile={profile}
      aboveContentComp={aboveContentComp}
      defaultDonationAmount={defaultDonationAmount}
    >
      <AboutTab profile={profile} />
      <PostsTab profile={profile} />
      {hasShopProducts ? <ShopTab username={profile.username} /> : undefined}
      {hasTiers ? (
        <MembershipsTab
          profileId={profile.id}
          returnTo={getRoute('profile', profile.username, { tab: 'memberships' })}
        />
      ) : undefined}
    </Layout>
  )
}
