import { Box, Button, Flex, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import {
  CardElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  PaymentMethod,
  PaymentRequestPaymentMethodEvent,
  PaymentRequestTokenEvent,
  StripeCardElement,
} from '@stripe/stripe-js'
import { AmountInput } from 'components/AmountInput'
import { InputWrapper } from 'components/InputWrapper'
import Link from 'components/Link'
import { LoadingGeneral } from 'components/LoadingGeneral'
import { StrikeThrough } from 'components/StrikeThrough'
import { StripeCard } from 'components/StripeCard'
import { Tier } from 'components/Tier'
import { useSession } from 'next-auth/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { MdOutlineEmail } from 'react-icons/md'
import { minimumAmountForCurrencies, TrackNamesEnum } from 'src/constants'
import {
  CreateSubscriptionMutation,
  TierQuery,
  useCompleteSubscriptionPaymentMutation,
  useCreateSubscriptionMutation,
  useTierQuery,
} from 'src/generated/graphql-frontend'
import { getRoute } from 'utils/helpers'
import { PaymentRequestProvider, usePaymentRequest } from 'utils/hooks'
import mixpanel from 'utils/mixpanel'
import { CurrencyType, getPrice } from 'utils/payments'
import validator from 'validator'
import {ErrorMessage} from 'components/ErrorMessage'

interface Form {
  amount: number
  name: string
  email: string
}

interface MembershipCheckoutInnerProps {
  returnTo?: string
  tier: NonNullable<TierQuery['tier']>
  onSuccess?: () => void
}

export const MembershipCheckoutInner = ({ returnTo, tier, onSuccess }: MembershipCheckoutInnerProps) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const user = useSession()
  const router = useRouter()
  const bg = useColorModeValue('white', 'gray.700')
  const { paymentRequest, setAmount } = usePaymentRequest()
  const [hasSetEvent, setHasSetEvent] = React.useState(false)
  const { t } = useTranslation('tier')
  const {
    formState: { isValid, errors },
    control,
    register,
    watch,
    reset,
    handleSubmit,
    getValues,
  } = useForm<Form>({
    defaultValues: {
      amount: tier.Product.Price.amount || 0,
      name: tier.lastNickname ?? undefined,
      email: user.status === 'authenticated' ? user.data?.user.email : '',
    },
    mode: 'all',
  })
  const amount = watch('amount')
  console.log('amount:', amount)
  const toast = useToast()
  const stripe = useStripe()
  const elements = useElements()
  const paymentMethodRef = React.useRef<PaymentMethod | null>()
  const [cardError, setCardError] = React.useState<string>()
  const displayError = React.useCallback(
    (message?: string) => {
      setIsLoading(false)
      if (message) {
        setCardError(message)
      } else {
        toast({
          title: t('errorWhileUsingYourCardToSubscribe'),
          position: 'top-right',
          status: 'error',
        })
      }
    },
    [toast]
  )
  const [completeSubscriptionPayment] = useCompleteSubscriptionPaymentMutation({
    onCompleted: (result) => {
      setIsLoading(false)
      reset()
      // TODO: Celebration
      toast({
        title: t('youWereSubscribed'),
        position: 'top-right',
        status: 'success',
      })
      const redirectTo = result.completeSubscriptionPayment.redirectTo
      const returnUrl =
        user.status === 'authenticated'
          ? getRoute('profile', tier.profileUsername)
          : getRoute('login')
      void router.push(redirectTo ?? returnUrl)
      onSuccess?.()
    },
    onError: () => {
      displayError()
    },
  })
  const [createSubscription, createSubscriptionData] = useCreateSubscriptionMutation({
    onCompleted: (props) => {
      const onComplete = async ({
        createSubscription: { clientSecret, subscriptionId },
      }: CreateSubscriptionMutation) => {
        const res = await stripe?.confirmCardPayment(
          clientSecret,
          paymentMethodRef.current
            ? { payment_method: paymentMethodRef.current.id }
            : {
                payment_method: {
                  card: elements?.getElement(CardElement) as StripeCardElement,
                  billing_details: { email: getValues('email'), name: getValues('name') },
                },
              }
        )
        if (res?.error) {
          displayError()
        } else {
          void completeSubscriptionPayment({
            variables: { subscriptionId: subscriptionId, returnTo },
          })
        }
      }
      void onComplete(props)
    },
  })

  const finishPaymentWithPaymentMethod = React.useCallback(
    async (formData: Pick<Form, 'name' | 'email'>, paymentMethod: PaymentMethod) => {
      const { name, email } = formData
      try {
        await createSubscription({
          variables: {
            inputData: {
              amount,
              customerName: name,
              email,
              paymentMethod: paymentMethod.id,
              tierId: tier.id,
            },
          },
        })
        return true
      } catch (err) {
        console.error(err)
        setIsLoading(false)
        return false
      } finally {
        return false
      }
    },
    [createSubscription, tier.id, amount]
  )

  const onSubmit = React.useCallback(
    async (formData: Form) => {
      setIsLoading(true)
      const tierId = tier.id
      if (!stripe || !elements || !tierId) {
        return false
      }
      try {
        const card = elements?.getElement(CardElement)
        if (!card) {
          throw new Error(t('cardNotSpecified'))
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: card,
        })
        if (error || !paymentMethod) {
          displayError(error?.message)
        } else {
          await finishPaymentWithPaymentMethod(formData, paymentMethod)
        }
      } catch (err) {
        displayError((err as Error).message)
      }
    },
    [stripe, elements, createSubscription, tier, displayError]
  )

  React.useEffect(() => {
    if (paymentRequest) {
      setHasSetEvent(false)
      const onPaymentMethod = async (event: PaymentRequestPaymentMethodEvent) => {
        const { paymentMethod } = event
        if (paymentMethod) {
          const formData = getValues()
          if (await finishPaymentWithPaymentMethod(formData, paymentMethod)) {
            event.complete('success')
          } else {
            event.complete('fail')
          }
        }
      }
      const handleToken = async (event: PaymentRequestTokenEvent) => {
        setIsLoading(true)
        const { token } = event
        // const { card } = token - TODO: Extract card details
        const { error, paymentMethod } = await stripe?.createPaymentMethod({
          type: 'card',
          card: { token: token.id },
          billing_details: {
            name: getValues('name'),
            email: getValues('email'),
          },
        }) as { error?: Error; paymentMethod?: PaymentMethod }
        console.log('paymentMethod:', paymentMethod)
        if (error || !paymentMethod) {
          displayError((error as Error).message)
          event.complete('fail')
        } else {
          await finishPaymentWithPaymentMethod({
            name: getValues('name'),
            email: getValues('email'),
          }, paymentMethod)
          event.complete('success')
        }
        setIsLoading(false)
      }

      paymentRequest.on('paymentmethod', onPaymentMethod)
      paymentRequest.on('token', handleToken)
      setHasSetEvent(true)
      return () => {
        paymentRequest.off('paymentmethod', onPaymentMethod)
        paymentRequest.off('token', handleToken)
        setHasSetEvent(false)
      }
    }
  }, [paymentRequest, finishPaymentWithPaymentMethod, getValues])

  React.useEffect(() => {
    setAmount(amount)
  }, [amount, setAmount])

  const currency: CurrencyType = tier ? tier.Product.Price.currency as CurrencyType : 'eur'
  const priceWithSymbol = tier ? getPrice(amount, currency) : ''

  return (
    <Stack
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      my={{ base: 2, md: '6' }}
      mx="auto"
      maxW={{
        base: 'full',
        md: 'lg',
      }}
      spacing="6"
    >
      <Stack
        alignItems="stretch"
        borderRadius={{
          base: 'none',
          md: '2xl',
        }}
        bg={bg}
        boxShadow={{
          base: 'none',
          md: '0px 18px 38px rgba(132, 132, 132, 0.2)',
        }}
        py={{ base: 4, md: 8 }}
        px={{ base: 1, md: 5 }}
        spacing="5"
      >
        <Stack
          w={{
            base: 'auto',
            md: 'md',
          }}
          maxW="full"
          p={{
            base: 1.5,
            md: 5,
          }}
          spacing="6"
          alignSelf="center"
          borderRadius="xl"
          border="1px solid"
          borderColor="rgba(66, 66, 66, 0.125)"
        >
          {tier && (
            <Tier
              tier={tier}
              alignItems="flex-start"
              textAlign="left"
              shouldShowJoinNow={false}
              hasShadow={false}
              isFullWidth
              isCheckout
              returnTo={getRoute('profile', tier.profileUsername)}
            />
          )}
          <Stack spacing="4">
            {tier && (
              <Controller
                control={control}
                name="amount"
                rules={{
                  required: { value: true, message: t('youMustEnterANumber') },
                  min: {
                    value: tier?.Product.Price.amount || minimumAmountForCurrencies[currency],
                    message: t('youMustPayAtLeast', {
                      amount: getPrice(tier.Product.Price.amount, tier.Product.Price.currency),
                    }),
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <AmountInput
                      width="full"
                      variant="outline"
                      label={t('payWhatYouWant')}
                      currencyName={currency}
                      errorMessage={error?.message}
                      {...field}
                    />
                  </>
                )}
              />
            )}
            <Flex p="4" justifyContent="space-between" borderRadius="lg" bgColor="gray.250">
              <Text fontWeight="medium">{t('total')}</Text>
              <Text fontWeight="medium">{t('amountPerMonth', { amount: priceWithSymbol })}</Text>
            </Flex>
            <InputWrapper
              {...register('name', { validate: (name) => !validator.isEmpty(name) })}
              type="text"
              variant="outline"
              placeholder={t('nameOrNickname')}
              hint={t('howDoYouWantToShowUp')}
              errorMessage={errors?.name && t('nameIsRequired')}
              size="sm"
            />
            <InputWrapper
              {...register('email', { validate: (email) => validator.isEmail(email || '') })}
              type="email"
              variant="outline"
              placeholder="Email"
              leftIcon={<MdOutlineEmail size={20} />}
              errorMessage={errors?.email && t('emailIsRequired')}
              size="sm"
            />
            {/* TODO */}
            {false && paymentRequest && hasSetEvent && (
              <>
                <Box position="relative">
                  {/* <PaymentRequestButtonElement options={{ paymentRequest }} /> */}
                  {!isValid && (
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      bottom="0"
                      borderRadius="xl"
                      bg="white"
                      opacity="0.5"
                    />
                  )}
                </Box>
                <StrikeThrough>{t('common:or')}</StrikeThrough>
              </>
            )}
            <StripeCard size="sm" cardError={cardError} setCardError={setCardError} />
            {createSubscriptionData.error?.message && (
              <ErrorMessage>
                {createSubscriptionData.error.message}
              </ErrorMessage>
            )}
            <Text textAlign="center" fontSize="0.7rem" opacity="0.6" w="full">
              {t('byClickingYouAgreeToOur')}{' '}
              <Link
                href={getRoute('termsAndConditions')}
                textDecoration="underline"
                target="_blank"
                style={{ boxShadow: 'none' }}
              >
                {t('termsAndConditions')}
              </Link>
            </Text>
            <Button
              type="submit"
              w="100%"
              isLoading={isLoading}
              onClick={() =>
                mixpanel.track(TrackNamesEnum.SubscribeMemberShip, {
                  tier,
                  ...getValues(),
                })
              }
            >
              {t('subscribeForPriceAmount', { amount: priceWithSymbol })}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

interface Props {
  tierId: string
  returnTo?: string
  onLoadTier?: (tier: TierQuery['tier']) => void
  onSuccess?: () => void
}

export const MembershipCheckout = ({ tierId, returnTo, onLoadTier, onSuccess }: Props) => {
  const { data } = useTierQuery({
    variables: { tierId },
    onCompleted: (data) => onLoadTier?.(data.tier),
  })
  const tier = data?.tier

  if (!tier) {
    return <LoadingGeneral />
  }

  return (
    <PaymentRequestProvider
      initialAmount={tier?.amountPerMonth}
      currency={tier.Product.Price.currency}
    >
      <MembershipCheckoutInner tier={tier} returnTo={returnTo} onSuccess={onSuccess} />
    </PaymentRequestProvider>
  )
}
