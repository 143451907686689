import React from 'react'
import { Stack, Heading } from '@chakra-ui/react'
import { useTiersQuery } from 'src/generated/graphql-frontend'
import { Tier } from 'components/Tier'

interface Props {
  profileId: string
}

export const Membership: React.FC<Props> = ({ profileId }) => {
  const { data } = useTiersQuery({ variables: { profileId } })
  return (
    <Stack w="full" spacing="5" pb="8" alignContent="stretch">
      <Heading p="6" pb="0" fontSize="lg">
        Choose a membership tier
      </Heading>
      <Stack px={{ base: 4, md: 8 }} spacing="1rem">
        {data?.tiers.map((tier) => (
          <Tier key={tier.id} tier={tier} isFullWidth />
        ))}
      </Stack>
    </Stack>
  )
}
