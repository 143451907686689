import {
  AspectRatio,
  Box,
  Button,
  chakra,
  Heading,
  Icon,
  Stack,
  StackProps,
  Text,
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { BsArrowDown } from 'react-icons/bs'
import { TrackNamesEnum } from 'src/constants'
import { useTiersQuery } from 'src/generated/graphql-frontend'
import { CheckoutModalPromiseAction, ModalPromise } from 'src/types/general'
import { getRoute } from 'utils/helpers'
import mixpanel from 'utils/mixpanel'
import { getPrice } from 'utils/payments'
import { getColorModeValue, getFadeGradient } from 'utils/theme/helpers'
import { CheckoutModal } from './Shop/CheckoutModal'
import { TextTag } from './TextTag'

interface Props extends StackProps {
  tier: NonNullable<ReturnType<typeof useTiersQuery>['data']>['tiers'][0]
  shouldShowJoinNow?: boolean
  showMerchantControls?: boolean
  isCheckout?: boolean
  isFullWidth?: boolean
  hasShadow?: boolean
  returnTo: string
}

export const Tier: React.FC<Props> = ({
  tier,
  isFullWidth,
  alignItems = 'center',
  hasShadow = true,
  isCheckout,
  shouldShowJoinNow = true,
  showMerchantControls,
  returnTo,
  ...props
}) => {
  const router = useRouter()
  const [isExpanded, setIsExpanded] = React.useState(isCheckout ? false : true)
  const [height, setHeight] = React.useState<number | null>(null)
  const heightRef = React.useRef<HTMLDivElement>(null)
  const [modalPromise, setModalPromise] = React.useState<ModalPromise<CheckoutModalPromiseAction>>()
  const { t } = useTranslation('tier')
  const {
    Product: {
      description,
      name,
      Pictures,
      Price: { amount, currency },
    },
    isRecurringDonation,
    id,
  } = tier
  const picture = Pictures?.[0]
  const openModal = async () => {
    try {
      const result = await new Promise<string | undefined>((resolve, reject) => {
        setModalPromise({
          action: {
            tierId: id,
          },
          resolve,
          reject,
        })
      })

      if (!result) {
        return
      }

      // show confetti

      await router.push(returnTo)
    } catch {
      return
    } finally {
      setModalPromise(undefined)
    }
  }
  const closeModal = () => {
    setModalPromise(undefined)
  }
  return (
    <Box
      flex="1"
      flexBasis="20rem"
      maxW={isFullWidth ? undefined : '25rem'}
      border={`${hasShadow ? 2 : 1}px solid`}
      borderColor={hasShadow ? 'gray.250' : 'gray.300'}
      borderRadius="3xl"
      alignItems={alignItems}
      boxShadow={hasShadow ? '0px 16px 40px rgba(132, 132, 132, 0.14)' : undefined}
      textAlign="center"
      {...props}
    >
      <Box
        w="full"
        borderRadius="3xl"
        borderBottomLeftRadius="0"
        borderBottomRightRadius="0"
        {...(picture?.thumbnailSignedUrl
          ? { bgImage: picture?.thumbnailSignedUrl }
          : { bgColor: 'primary.500' })}
        bgSize="cover"
        bgPos="center"
        bgRepeat="no-repeat"
        position="relative"
      >
        <AspectRatio ratio={2.125}>
          <div />
        </AspectRatio>
        {isRecurringDonation && (
          <Heading
            position="absolute"
            left="0"
            top="50%"
            w="full"
            size="sm"
            transform="translateY(-50%)"
            color="white"
          >
            {t('monthlyMembership')}
          </Heading>
        )}
      </Box>
      <Stack p={{ base: '3.5', md: '6' }} spacing={{ base: '3.5', md: '6' }}>
        {!isCheckout && (
          <Stack spacing="1">
            <Heading size="xl">{getPrice(amount, currency)}</Heading>
            <TextTag color="gray.700" opacity={0.6}>
              {t('perMonth')}
            </TextTag>
          </Stack>
        )}
        <Heading fontSize={isCheckout ? 'md' : 'lg'}>{name}</Heading>
        {shouldShowJoinNow && (
          <Stack pb={{ base: '4', md: '8' }} flex="none" alignItems="center">
            <Button
              w="48"
              maxW="full"
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              onClick={async () => {
                console.log('Tier openModal')
                await openModal()
                mixpanel.track(TrackNamesEnum.JoinMembership, {
                  tier,
                })
              }}
            >
              {t('joinNow')}
            </Button>
          </Stack>
        )}
        <chakra.div
          pos="relative"
          overflow="hidden"
          minH={20}
          h={`${isExpanded ? height : 20}px`}
          transition="height 0.15s ease-out"
        >
          <Text
            ref={heightRef}
            as="div"
            textAlign="left"
            fontSize={isCheckout ? 'xs' : 'sm'}
            __css={{
              color: getColorModeValue('gray.700', 'gray.300'),
            }}
            opacity={0.6}
            pb={isCheckout ? 8 : 0}
          >
            {description}
          </Text>
          {isCheckout && heightRef.current && heightRef.current.clientHeight > 20 && <chakra.div
            as="button"
            pos="absolute"
            role="button"
            type="button"
            cursor="pointer"
            bottom={0}
            left={0}
            right={0}
            height={8}
            __css={{ bg: getFadeGradient(70, 180) }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              setIsExpanded((isExpanded) => !isExpanded)
              const { clientHeight } = heightRef.current ?? {}
              if (clientHeight) {
                setHeight(clientHeight)
              }
            }}
          >
            <Icon
              as={BsArrowDown}
              w="4"
              h="4"
              transition="transform 0.125s ease-out"
              transform={`rotateX(${isExpanded ? 180 : 0}deg)`}
            />
          </chakra.div>}
        </chakra.div>
        {showMerchantControls && (
          <Stack direction="column" alignItems="center">
            <Button
              fontWeight="medium"
              variant="link"
              size="sm"
              textDecoration="underline"
              as={Link}
              href={getRoute('tier', id)}
            >
              {t('editTier')}
            </Button>
          </Stack>
        )}
      </Stack>
      <CheckoutModal
        username={tier.profileUsername}
        tierId={tier.id}
        isLoading={false}
        returnTo={returnTo}
        modalPromise={modalPromise}
        onClose={closeModal}
      />
    </Box>
  )
}
