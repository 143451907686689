const isEsoteriq = process.env.NEXT_PUBLIC_APP_URL.toLowerCase().includes('esoteriq')

export const i18nConfig = {
  locales: isEsoteriq ? ['fr', 'en', 'sk'] : ['en', 'sk'],
  defaultLocale: isEsoteriq ? 'fr' : 'en',
  pages: {
    '*': [
      'account',
      'auth',
      'backOffice',
      'common',
      'community',
      'creatorPost',
      'datetime',
      'homePage',
      'merchant',
      'order',
      'orderTracking',
      'profile',
      'settings',
      'tier',
      'username',
      'widget',
      'order',
      'notifications',
      'pushNotifications',
    ],
  },
  loadLocaleFrom: (lang, ns) =>
    import(`../../locales/${lang ?? ''}/${ns}.json`).then(
      (m: { default: Record<string, string> }) => m.default
    ),
}
