import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Flex, Box, useRadio, useTheme, useRadioGroup, useColorMode } from '@chakra-ui/react'
import { getColorVar } from '@chakra-ui/theme-tools'
import { CurrencyType, getPrice } from 'utils/payments'
import { EmbedProps } from 'components/Widget/constants'
import { minimumAmountForCurrencies } from 'src/constants'

type OptionProps = ReturnType<ReturnType<typeof useRadioGroup>['getRadioProps']> &
  Pick<AmountPickProps, 'colorScheme'>

const getRadioCardColor = (
  props: { isChecked: boolean, colorMode: 'dark' | 'light' } & Pick<AmountPickProps, 'colorScheme'>
) => {
  const { colorScheme, colorMode } = props
  if (colorScheme) {
    return props.isChecked
      ? { bg: colorScheme, color: 'white' }
      : { bg: colorMode === 'light' ? 'gray.200' : 'whiteAlpha.100', color: colorScheme }
  }
  return props.isChecked
    ? { bg: 'primary.500', color: 'white' }
    : { bg: 'gray.200', color: 'primary.500' }
}

const RadioCard: React.FC<OptionProps> = ({ colorScheme, children, ...props }) => {
  const theme = useTheme()
  const { colorMode } = useColorMode()
  const {
    getInputProps,
    getRadioProps,
    state: { isChecked },
  } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()
  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        display="flex"
        alignItems="center"
        m="0.25rem"
        px="5"
        py="2.5"
        fontSize={{ base: 'sm', md: 'md' }}
        borderRadius="xl"
        cursor="pointer"
        userSelect="none"
        boxShadow="0 0 0 1px transparent"
        transitionProperty="common"
        transitionDuration="fast"
        _focus={{
          boxShadow: {
            base: 'none',
            md: `0 0 0 1px ${getColorVar(theme, 'primary.500') as string}`,
          },
        }}
        __css={{
          WebkitTapHighlightColor: 'rgba(19, 52, 131, 0.25)',
        }}
        {...getRadioCardColor({ isChecked, colorScheme, colorMode })}
      >
        {children}
      </Box>
    </Box>
  )
}

const getOptionBasedOnCurrency = (currency: CurrencyType) => {
  const minimumAmount = minimumAmountForCurrencies[currency]
  switch (currency) {
    case 'czk':
      return [minimumAmount, 10000, 15000, 20000, 50000, 100000]
    case 'eur':
    case 'usd':
    default:
      return [500, 1000, 2500, 5000, 10000, 15000]
  }
}

interface AmountPickProps {
  name: string
  value?: number
  currency: string
  onChange(newValue?: number): void
  colorScheme: NonNullable<EmbedProps['profile']['productSettings']>['widgetColorScheme']
}

export const AmountPick: React.FC<AmountPickProps> = ({
  value,
  name,
  onChange,
  currency,
  colorScheme,
}) => {
  const { t } = useTranslation('common')
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value: String(value) || undefined,
    onChange: (newValue: string) => onChange(newValue ? parseFloat(newValue) : undefined),
  })
  const options = [
    ...getOptionBasedOnCurrency(currency as CurrencyType).map((value) => ({
      label: `${getPrice(value, currency)}`,
      value,
    })),
    { label: t('other'), value: 0 },
  ]
  const group = getRootProps()
  return (
    <Flex flexWrap="wrap" justifyContent="center" maxW="420px" mx="auto" {...group}>
      {options.map(({ value, label }) => {
        const radio = getRadioProps({ value: String(value) })
        return (
          <RadioCard colorScheme={colorScheme} key={value} {...radio}>
            {label}
          </RadioCard>
        )
      })}
    </Flex>
  )
}
