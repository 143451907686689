import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { UseFormReturn } from 'react-hook-form'
import { PartialBlock } from '@blocknote/core/src/blocks/defaultBlocks'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { InputWrapper } from 'components/InputWrapper'
import { SimpleTextEditor } from 'components/SimpleTextEditor'

interface Props {
  innerRef?: React.RefObject<HTMLDivElement>
  shopProductForm: UseFormReturn<ShopProductInput, any, undefined>
}

const getInitialContentJson = (content: string | null) => {
  if (!content) {
    return undefined
  }
  try {
    return JSON.parse(content) as PartialBlock[]
  } catch {
    return undefined
  }
}

export const NameDescription: React.FC<Props> = ({ shopProductForm }) => {
  const { t } = useTranslation('backOffice')
  const initialDescription = shopProductForm.getValues('description')

  return (
    <div>
      <InputWrapper
        {...shopProductForm.register('name')}
        label={t('Product.nameDescriptionNameInputLabel')}
        variant="outline"
        maxW="full"
      />
      <SimpleTextEditor initialContent={getInitialContentJson(initialDescription ?? null)} placeholder={t('Product.nameDescriptionDescriptionInputPlaceholder')} onChangeContent={(contentJson) => {shopProductForm.setValue('description', contentJson)}} label={t('Product.nameDescriptionDescriptionInputLabel')} />
    </div>
  )
}
