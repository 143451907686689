import React from 'react'
import { useSession } from 'next-auth/react'
import { Divider, HTMLChakraProps, Stack, Text } from '@chakra-ui/react'
import {
  FaCrown,
  FaHeart,
  FaHome,
  FaStar,
  FaStore,
  FaPeopleCarry,
  FaFileSignature,
  FaRegClock,
} from 'react-icons/fa'
import { GoGear } from 'react-icons/go'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { BiLogOut } from 'react-icons/bi'
import { BsQrCode } from 'react-icons/bs'
import { MdCardMembership, MdOutlineCalendarMonth } from 'react-icons/md'
import { UserRoleEnum } from 'src/generated/graphql-frontend'
import { getRoute, logout } from 'utils/helpers'
import { ProfilePicture } from 'components/ProfilePicture'
import blank from 'public/images/blank.png'
import { MyProfiles } from './MyProfiles'
import { CustomMenuButton } from './CustomMenuButton'
import useTranslation from 'next-translate/useTranslation'
import { LuLink } from 'react-icons/lu'

interface MerchantMenuProps extends HTMLChakraProps<'div'> {
  isDrawer?: boolean
  onClose?(): void
}

export const MerchantMenu: React.FC<MerchantMenuProps> = ({ isDrawer, onClose, ...props }) => {
  const { t } = useTranslation('merchant')
  const session = useSession()

  const username = session.data?.user.profile?.username

  const personalItems = [
    {
      label: t('MerchantMenu.myMemberships'),
      link: getRoute('account'),
      icon: MdCardMembership,
    },
    { label: t('MerchantMenu.bookings'), link: getRoute('bookings'), icon: MdOutlineCalendarMonth },
    { label: t('MerchantMenu.community'), link: getRoute('community'), icon: FaPeopleCarry },
    { label: t('MerchantMenu.settings'), link: getRoute('account', 'profile'), icon: GoGear },
  ]

  const items = [
    { label: t('MerchantMenu.dashboard'), link: getRoute('backoffice'), icon: FaHome },
    {
      label: t('MerchantMenu.creatorPosts'),
      link: getRoute('backoffice', 'posts'),
      icon: FaFileSignature,
    },
    { label: t('MerchantMenu.shop'), link: getRoute('backoffice', 'shop'), icon: FaStore },
    {
      label: t('MerchantMenu.bookings'),
      link: getRoute('backoffice', 'bookings'),
      icon: FaRegClock,
    },
    {
      label: t('MerchantMenu.instantPayment'),
      link: getRoute('backoffice', 'instant-payment'),
      icon: BsQrCode,
    },
    {
      label: t('MerchantMenu.supporters'),
      link: getRoute('backoffice', 'supporters'),
      icon: FaHeart,
    },
    {
      label: t('MerchantMenu.memberships'),
      link: getRoute('backoffice', 'memberships'),
      icon: HiOutlineUserGroup,
    },
    ...(username
      ? [{
          label: t('MerchantMenu.storeLink'),
          link: getRoute('profile', username, { storeLink: true }),
          icon: LuLink,
        }]
      : []),
    {
      label: t('MerchantMenu.widgetAndButton'),
      link: getRoute('backoffice', 'widget'),
      icon: FaStar,
    },
    {
      label: t('MerchantMenu.merchantSettings'),
      link: getRoute('backoffice', 'settings'),
      icon: GoGear,
    },
  ]

  const userLabel = session.data?.user.personalProfile?.name ||
    session.data?.user.email ||
    t('MerchantMenu.personalAccount')

  return (
    <Stack spacing="6" borderColor="#EFF5FF" {...props}>
      <Stack direction="row" alignItems="center" spacing="2.5">
        <ProfilePicture
          bgImage={`url(${
            session.data?.user.personalProfile?.profilePicture?.thumbnailSignedUrl || blank.src
          })`}
        />
        <Stack spacing={0}>
          <Text noOfLines={2} fontWeight="medium" fontSize={userLabel!.length > 20 ? 'sm' : '0.93rem'} wordBreak="break-word" lineHeight="1.2">
            {userLabel}
          </Text>
          <Text fontSize="12px" color="gray.500">
            {t('MerchantMenu.personalAccount')}
          </Text>
        </Stack>
      </Stack>
      {personalItems.map(({ label, link, icon }, index) => (
        <CustomMenuButton key={link} isFirst={index === 0} href={link} leftIcon={icon}>
          {label}
        </CustomMenuButton>
      ))}
      <CustomMenuButton leftIcon={BiLogOut} onClick={logout}>
        {t('MerchantMenu.signOut')}
      </CustomMenuButton>
      <Divider borderBottomWidth="2px" />
      <MyProfiles onSwitched={onClose} />
      {!!session.data?.user.profile &&
        items.map(({ label, link, icon }, index) => (
          <CustomMenuButton key={link} isFirst={index === 0} href={link} leftIcon={icon}>
            {label}
          </CustomMenuButton>
        ))}
      {session.data?.user.role === UserRoleEnum.SuperAdmin && (
        <CustomMenuButton href={getRoute('backoffice', 'admin')} leftIcon={FaCrown}>
          {t('MerchantMenu.admin')}
        </CustomMenuButton>
      )}
    </Stack>
  )
}
