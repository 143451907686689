import React, { useEffect } from 'react'
import { useElements, useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js'
import { CheckoutMethodProps } from '../types'
import {
  PaymentTypeEnum,
  useCompleteStripePaymentMutation,
  useCreatePaymentIntentMutation,
} from 'src/generated/graphql-frontend'
import { extractExtensionErrorMessage } from './helpers'
import { Box } from '@chakra-ui/react'
import { usePaymentRequest } from 'utils/hooks'

export const PaymentRequestComponent = ({
  amountCents,
  productInput,
  getEmail,
  onGoNext,
  setPaymentError,
  onObtainedPersonalInfo,
}: CheckoutMethodProps) => {
  const { paymentRequest, canMakePaymentResult } = usePaymentRequest()
  const stripe = useStripe()
  const elements = useElements()

  const [createPaymentIntent] = useCreatePaymentIntentMutation()
  const [completeStripePayment] = useCompleteStripePaymentMutation({
    onError(error) {
      console.error('Complete payment error:', error)
      setPaymentError?.(error.message)
    },
  })

  useEffect(() => {
    const handlePaymentMethodEvent = async (event: PaymentRequestPaymentMethodEvent) => {
      try {
        const personalInfo = event.paymentMethod.billing_details
        if (personalInfo && onObtainedPersonalInfo) {
          await onObtainedPersonalInfo(personalInfo)
        }

        if (!stripe || !elements) {
          event.complete('fail')
          throw new Error('Stripe.js has not loaded')
        }

        const { data } = await createPaymentIntent({
          variables: {
            amount: amountCents,
            type:
              event.walletName === 'applePay'
                ? PaymentTypeEnum.ApplePay
                : event.walletName === 'googlePay'
                ? PaymentTypeEnum.GooglePay
                : PaymentTypeEnum.Card,
            productInput,
          },
        })

        const clientSecret = data?.createPaymentIntent.clientSecret
        if (!clientSecret) {
          event.complete('fail')
          throw new Error('Failed to create payment intent')
        }

        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: event.paymentMethod.id,
        })

        if (stripeError) {
          event.complete('fail')
          throw new Error(stripeError.message)
        }

        event.complete('success')

        if (paymentIntent?.status === 'requires_capture') {
          const { data } = await completeStripePayment({
            variables: { stripePaymentId: paymentIntent.id, email: getEmail() },
          })

          if (
            data?.completeStripePayment?.__typename === 'PaymentResult' &&
            data.completeStripePayment.success
          ) {
            onGoNext?.({ paymentId: paymentIntent.id, isSuccess: true })
            return
          } else if (data?.completeStripePayment?.__typename === 'OrderPaymentIntentError') {
            const error = extractExtensionErrorMessage(data.completeStripePayment)
            setPaymentError?.(error)
            throw new Error(error)
          }
        }

        onGoNext?.({ isSuccess: false })
      } catch (error) {
        console.error('Payment method event error:', error)
        setPaymentError?.((error as Error)?.message)
        onGoNext?.({ isSuccess: false })
      }
    }

    if (paymentRequest) {
      paymentRequest.on('paymentmethod', handlePaymentMethodEvent)
      return () => {
        paymentRequest.off('paymentmethod', handlePaymentMethodEvent)
      }
    }
  }, [
    paymentRequest,
    stripe,
    elements,
    amountCents,
    productInput,
    getEmail,
    onObtainedPersonalInfo,
    onGoNext,
    createPaymentIntent,
    completeStripePayment,
    setPaymentError,
  ])

  if (!paymentRequest || !(canMakePaymentResult?.applePay || canMakePaymentResult?.googlePay)) {
    return null
  }

  return (
    <Box position="relative">
      <PaymentRequestButtonElement options={{ paymentRequest }} />
      {!paymentRequest && <Box position="absolute" top={0} left={0} right={0} bottom={0} />}
    </Box>
  )
}

PaymentRequestComponent.displayName = 'PaymentRequestComponent'
