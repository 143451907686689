/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { motion } from 'framer-motion'
import {
  useBreakpointValue,
  Heading,
  BoxProps,
  DrawerProps,
  ModalProps,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Description } from 'components/Description'

const mobileDrawerPaddingY = 6

const ComponentsModal = {
  Wrapper: Modal,
  Overlay: ModalOverlay,
  // @ts-expect-error
  Content: motion(ModalContent),
  CloseButton: ModalCloseButton,
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
}

const ComponentsDrawer = {
  Wrapper: Drawer,
  Overlay: DrawerOverlay,
  // @ts-expect-error
  Content: motion(DrawerContent),
  CloseButton: DrawerCloseButton,
  Header: DrawerHeader,
  Body: DrawerBody,
  Footer: DrawerFooter,
}

interface ModalOrDrawerProps extends Omit<ModalProps, 'size'>, Omit<DrawerProps, 'size'> {
  title?: React.ReactNode
  description?: React.ReactNode
  footer?: React.ReactNode
  maxW?: BoxProps['maxW']
  hasPx?: boolean
  hasCloseButton?: boolean
  size?: ModalProps['size']
}

// TODO: Fix showing profiles on mobile

export const ModalOrDrawer: React.FC<ModalOrDrawerProps> = ({
  title,
  description,
  footer,
  hasPx = true,
  maxW = { base: 'xl', md: '7xl' },
  hasCloseButton = true,
  children,
  ...props
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const Components = isMobile ? ComponentsDrawer : ComponentsModal
  // TODO: Browser navbar is blocking full height of buttons in footer
  // const { isOpen } = props
  // React.useEffect(() => isOpen && window.scrollTo(0, 20), [isOpen])
  return (
    <Components.Wrapper {...props} size={isMobile ? 'full' : undefined} placement="bottom">
      <Components.Overlay width="full" h="full" />
      <Components.Content
        // TODO: Check if this is used anywhere, was problem bc of react-select
        // overflow="hidden"
        maxW={maxW}
        p={hasPx ? undefined : '0'}
        py={isMobile ? mobileDrawerPaddingY : undefined}
        h={isMobile ? 'var(--chakra-vh)' : undefined}
      >
        {hasCloseButton && (
          <Components.CloseButton
            right={isMobile ? mobileDrawerPaddingY : undefined}
            top={isMobile ? mobileDrawerPaddingY : undefined}
          />
        )}
        {title && (
          <Components.Header>
            <Heading size="lg">{title}</Heading>
            {description && (
              <Description fontWeight="normal" pt="2" opacity={0.6}>
                {description}
              </Description>
            )}
          </Components.Header>
        )}
        <Components.Body position="relative" p={hasPx ? undefined : '0'}>
          {children}
        </Components.Body>

        {footer && <Components.Footer justifyContent="space-between">{footer}</Components.Footer>}
      </Components.Content>
    </Components.Wrapper>
  )
}
