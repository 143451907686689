import { useStripe } from '@stripe/react-stripe-js'
import {
  PaymentRequest,
  PaymentRequestWallet
} from '@stripe/stripe-js'
import useTranslation from 'next-translate/useTranslation'
import { createContext, useContext, useEffect, useState } from 'react'
import { ProfileQuery, ThumbnailSizeEnum, useProfileQuery } from 'src/generated/graphql-frontend'

type CanMakePaymentResultType = Record<PaymentRequestWallet, boolean>

interface PaymentRequestContextValue {
  isLoading: boolean
  canMakePaymentResult: CanMakePaymentResultType | null
  /**
   * Indicates if Google / Apple Pay is available, otherwise null.
   */
  paymentRequest: PaymentRequest | null
  isRecurringDonation: boolean | null
  /**
   * Amount in cents.
   */
  amount: number | null
  setAmount: (amount: number | null) => void
  currency: string | null
  profile: ProfileQuery['profile'] | null
}

interface Props {
  profileId?: string
  currency?: string | null
  /**
   * Controlled amount in cents.
   */
  amount?: number
  /**
   * Initial amount in cents.
   */
  initialAmount?: number
  children: React.ReactNode
}

const PaymentRequestContext = createContext<PaymentRequestContextValue>({
  isLoading: true,
  canMakePaymentResult: null,
  paymentRequest: null,
  isRecurringDonation: null,
  amount: null,
  setAmount: () => {},
  currency: null,
  profile: null,
})

export const PaymentRequestProvider = ({
  profileId,
  amount,
  currency: currencyProp = null,
  initialAmount = 500,
  children,
}: Props) => {
  const stripe = useStripe()
  const { t } = useTranslation('common')
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null)
  const [canMakePaymentResult, setCanMakePaymentResult] = useState<CanMakePaymentResultType | null>(null)
  const [isRecurringDonation] = useState(false)
  const [amountState, setAmountState] = useState<number | null>(amount || initialAmount)

  const { data: profile } = useProfileQuery({
    variables: {
      id: profileId,
      coverPictureSize: ThumbnailSizeEnum.Small,
      size: ThumbnailSizeEnum.Small,
    },
    skip: !profileId,
  })

  const currency = profile?.profile?.currency || currencyProp
  const finalAmount = amount || amountState

  useEffect(() => {
    if (stripe && currency && finalAmount) {
      const newPaymentRequest = stripe.paymentRequest({
        country: 'US', // Replace with dynamic country if available
        currency,
        total: {
          label: t('payOrder'),
          amount: finalAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ['link'],
      })

      newPaymentRequest
        .canMakePayment()
        .then((result) => {
          if (result) {
            setCanMakePaymentResult(result as CanMakePaymentResultType)
            setPaymentRequest(newPaymentRequest)
          }
        })
        .catch((error) => {
          console.error('Error in canMakePayment:', error)
        })
    }
  }, [stripe, currency, finalAmount])

  return (
    <PaymentRequestContext.Provider
      value={{
        isLoading: !stripe,
        canMakePaymentResult,
        paymentRequest,
        isRecurringDonation,
        amount: finalAmount,
        setAmount: setAmountState,
        currency,
        profile: profile?.profile || null,
      }}
    >
      {children}
    </PaymentRequestContext.Provider>
  )
}

export const usePaymentRequest = () => useContext(PaymentRequestContext)
