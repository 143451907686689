import { Button, Flex, Grid, Stack, Text, chakra } from '@chakra-ui/react'
import { Description } from 'components/Description'
import { InputWrapper } from 'components/InputWrapper'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import { format } from 'date-fns'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { useForm } from 'react-hook-form'
import { FindMultipleTimeSlotsWithSameOrderMutation } from 'src/generated/graphql-frontend'
import { CurrencyType, getPrice } from 'utils/payments'
import { getFullName } from 'utils/helpers'

import type { ModalPromise, ModalPromiseAction } from 'src/types/general'

interface Props {
  start: Date
  end: Date
  /**
   * In case this time slot is ties to an order which has multiple time slots,
   * the worker can approve or reject only all of them at once
   */
  affectedTimeSlots?: FindMultipleTimeSlotsWithSameOrderMutation['findMultipleTimeSlotsWithSameOrder']
  multiPromise?: ModalPromise<ModalPromiseAction>
  currency?: CurrencyType
  /**
   * Whether order status {@enum OrderStatusEnum.Paid}, which if cancelled will cause refund transaction fee upon merchant
   */
  isOrderAlreadyPaid?: boolean
  isLoading: boolean
}

interface Form {
  message: string
}

export const RejectTimeSlotModal = ({
  start,
  isOrderAlreadyPaid,
  end,
  affectedTimeSlots,
  multiPromise,
  currency,
  isLoading,
}: Props) => {
  const { t } = useTranslation('backOffice')

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Form>()

  const isOpen = !!multiPromise
  React.useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const onClose = () => {
    multiPromise?.reject()
  }

  const handleFormSubmit = ({ message }: Form) => {
    multiPromise?.resolve(message)
  }

  const isApprove = multiPromise?.action === 'approve'
  const translationKey = isApprove
    ? 'Bookings.MultiApproveModal'
    : isOrderAlreadyPaid
      ? 'Bookings.CancelModal'
      : 'Bookings.RejectModal'
  const hasManyTimeSlots = (affectedTimeSlots?.length ?? 0) > 1
  const multiSufix = hasManyTimeSlots ? 'Multi' : ''

  return (
    <ModalOrDrawer
      title={t(`${translationKey}.title`)}
      description={
        isApprove
          ? undefined
          : t(`${translationKey}.description`, {
              start: format(start, 'dd. MM. HH:mm'),
              end: format(end, 'HH:mm'),
            })
      }
      maxW="container.sm"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Stack as="form" spacing={4} onSubmit={handleSubmit(handleFormSubmit)}>
        {hasManyTimeSlots && (
          <Description as="div" color="peach.500">
            {t(`${translationKey}.multiple`)}
          </Description>
        )}
        {!isApprove && isOrderAlreadyPaid && (
          <Description as="div" color="red">
            {t(`${translationKey}.feeWarning`)}
          </Description>
        )}
        {!isApprove && (
          <InputWrapper
            {...register('message')}
            placeholder={t(`${translationKey}.messagePlaceholder${multiSufix}`)}
          />
        )}
        {hasManyTimeSlots && (
          <Grid templateColumns="repeat(2, 1fr) auto auto" gap={6} pb={6}>
            {affectedTimeSlots?.map(
              ({ ContactInfo, ProductVariantOrder, id, sessionEnd, start }) => {
                const startDateTime = new Date(start as string)
                return (
                  <React.Fragment key={id}>
                    <div>
                      <Text fontSize="md" fontWeight="medium">
                        {getFullName(ContactInfo)}
                      </Text>
                      <Text fontSize="sm">{ContactInfo?.email}</Text>
                    </div>
                    <div>
                      <Text fontSize="1.05rem">
                        <span>{ProductVariantOrder?.ProductVariant.name} </span>
                        <chakra.span fontWeight="medium" color="teal.400">
                          {format(startDateTime, 'dd. MM.')}
                        </chakra.span>
                      </Text>
                    </div>
                    <div>
                      <Text fontWeight="medium">{format(startDateTime, 'HH:mm')}</Text>
                      <Text color="teal.200">
                        {format(new Date(sessionEnd as string), 'HH:mm')}
                      </Text>
                    </div>
                    <div>
                      {currency && (
                        <Text fontWeight="medium" color={isApprove ? 'green.400' : 'red.500'}>
                          {getPrice(ProductVariantOrder!.ProductVariant.Price.amount, currency)}
                        </Text>
                      )}
                    </div>
                  </React.Fragment>
                )
              }
            )}
          </Grid>
        )}
        <Flex gap={3} justify="flex-end">
          <Button variant="outline" onClick={onClose}>
            {t(`${translationKey}.close`)}
          </Button>
          {isApprove ? (
            <Button colorScheme="green" type="submit" isLoading={isSubmitting || isLoading}>
              {t(`${translationKey}.approveBtn${multiSufix}`)}
            </Button>
          ) : (
            <Button colorScheme="red" type="submit" isLoading={isSubmitting || isLoading}>
              {t(`${translationKey}.rejectBtn${multiSufix}`)}
            </Button>
          )}
        </Flex>
      </Stack>
    </ModalOrDrawer>
  )
}
