import { FullStory } from '@fullstory/browser'
import { Prisma, S3File as PrismaS3File } from '@prisma/client'
import loadImage from 'blueimp-load-image'
import { ProfileForm } from 'components/BackOffice/Settings/tabs/ProfileTab/types'
import { countries } from 'countries-list'
import { format, formatISO } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import isObject from 'lodash/isObject'
import keyBy from 'lodash/keyBy'
import transform from 'lodash/transform'
import { signOut } from 'next-auth/react'
import getTranslationFn from 'next-translate/getT'
import React from 'react'
import {hrInSec, RouteKeyType, RoutesFunc, URLEnum} from 'src/constants'
import {OrderByDirectionEnum, ProfileInput, FileTypeEnum, S3File, ThumbnailSizeEnum} from 'src/generated/graphql-backend'
import { SocialMediaNetworkType } from 'src/types/general'
import { i18nConfig } from 'utils/i18n'
import mixpanel from 'utils/mixpanel'
import {signObject} from 'utils/s3'
import {Context} from '../graphql/context'

export const getIsSsr = () => typeof window === 'undefined'

const { NEXT_PUBLIC_STORAGE_PUBLIC_BUCKET_URL } = process.env

export const getPublicUrl = (key?: string) =>
  key ? `${NEXT_PUBLIC_STORAGE_PUBLIC_BUCKET_URL}/${key}` : ''

export const getApiS3FileByTokenUrl = (key?: string, thumbnailSize?: ThumbnailSizeEnum) =>
  key ? `/api/get-s3file-by-token?key=${key}&thumbnailSize=${thumbnailSize}` : ''

export const getGQLS3File = (s3File: PrismaS3File) => ({
  ...s3File,
  name: s3File.name as string,
  type: s3File.type as FileTypeEnum,
  createdAt: formatISO(s3File.createdAt),
  updatedAt: formatISO(s3File.updatedAt),
  signExpiresAt: s3File.signExpiresAt ? formatISO(s3File.signExpiresAt) : null,
})

type GetSignedUrlParams = {
  prisma: Context['prisma']
  s3File: Partial<S3File>
}

export const getSignedUrl = async ({ prisma, s3File }: GetSignedUrlParams) => {
  if (s3File.isPublic) {
    return getPublicUrl(s3File.key)
  }
  if (s3File.signExpiresAt && s3File.signExpiresAt > new Date(Date.now() + 3000)) {
    return s3File.signedUrl as string
  }
  const signedUrl = await signObject({
    Bucket: process.env.PRIVATE_STORAGE_BUCKET,
    Key: s3File.key as string,
    Expires: hrInSec,
  })
  await prisma.s3File.update({
    where: {
      id: s3File.id,
    },
    data: {
      signedUrl,
      signExpiresAt: new Date(Date.now() + hrInSec * 1000),
    },
  })
  return signedUrl as string
}

export const fixRotationOfFile = (file: File) =>
  new Promise<Blob>((resolve) => {
    loadImage(
      file,
      (img) =>
        (img as HTMLCanvasElement).toBlob(
          (blob) => {
            if (blob) {
              resolve(blob)
            }
          },
          file.type,
          1
        ),
      {
        orientation: true,
        canvas: true,
        downsamplingRatio: 0,
        imageSmoothingEnabled: false,
      }
    )
  })

const EXTENSION_SEPARATOR = '.'

export const getFileExtensionFromName = (name: string) =>
  name.slice(name.lastIndexOf(EXTENSION_SEPARATOR))

export const stripFileExtensionFromName = (name: string) =>
  name.slice(0, name.lastIndexOf(EXTENSION_SEPARATOR))

const getPrismaSortOrder = (
  orderByDirection?: string | null,
  defaultOrder: Prisma.SortOrder = 'desc'
) => (orderByDirection?.toLowerCase() as Prisma.SortOrder) || defaultOrder

type OrderBy = { orderBy: { [key: string]: ReturnType<typeof getPrismaSortOrder> } }

type OrderByParams = {
  orderBy?: string | null
  orderByDirection?: OrderByDirectionEnum | null
  defaultOrder?: Prisma.SortOrder
}

type GetOrderBy = (data: OrderByParams) => OrderBy

export const getOrderBy: GetOrderBy = ({ orderBy, orderByDirection, defaultOrder }) => ({
  orderBy: {
    [orderBy || 'createdAt']: getPrismaSortOrder(orderByDirection, defaultOrder),
  },
})

type PageFromOffset = {
  page: string | undefined | null
  limitPerPage: number
}

export const getPageFromOffset = ({ page, limitPerPage }: PageFromOffset) =>
  limitPerPage * (Number.parseInt(page || '1') - 1)

type GetProfileURLParams = NonNullable<ProfileForm['socialMediaAccounts']>[0]

export const getProfileURL = (acc: GetProfileURLParams) => {
  const { network } = acc
  const url = acc.url as string
  switch (network as SocialMediaNetworkType) {
    case 'facebook':
      return `https://facebook.com/${url}`
    case 'instagram':
      return `https://instagram.com/${url}`
    case 'spotify':
      return url
    case 'twitch':
      return `https://twitch.tv/${url}`
    case 'twitter':
      return `https://twitter.com/${url}`
    case 'youtube':
      return url
    default:
      return ''
  }
}

export const hashCode = function (input: string) {
  let hash = 0,
    chr
  if (input.length === 0) return hash
  for (let i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i)
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const isValidWebsiteUrl = (incommingUrl: string) => {
  let url: URL
  try {
    url = new URL(incommingUrl)
  } catch (_) {
    return incommingUrl.startsWith(URLEnum.www) && !!incommingUrl.split('.')[2]
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const getIsProfilePublic = (profile: ProfileInput) => {
  const {
    description,
    username,
    label,
    labelColorScheme,
    name,
    coverPictureKey,
    profilePictureKey,
  } = profile
  return !!(
    description?.trim().length &&
    username?.trim().length &&
    label?.trim().length &&
    labelColorScheme?.trim().length &&
    coverPictureKey?.trim().length &&
    profilePictureKey?.trim().length &&
    name?.trim().length
  )
}

export const deepOmit = <T extends Record<any, any>, S extends Record<any, any>>(
  obj1: T | S,
  keysToOmit: keyof T | (keyof T)[]
) => {
  const keysToOmitIndex = keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit])
  const omitFromObject = (obj2: Record<any, any>) =>
    transform(obj2, (result: Record<any, any>, value, key: string) => {
      if (key in keysToOmitIndex) {
        return
      }
      result[key] = isObject(value) ? omitFromObject(value) : value
    })
  return omitFromObject(obj1)
}

export const fieldHasValueValidation = (errorMessage: string) => (value: string) =>
  !!value?.toString().length || errorMessage

// type GetV<T, TType = 'string' | 'int' | 'datetime'> = (
//   attributeValue: { valueStr?: string; valueInt?: string },
//   type: TType
// ) => T | undefined | null

// type GetValueDate = GetV<Date, 'datetime'>

export function getProductAssignmentValue<T>(
  attributeValue: { valueStr?: string; valueInt?: string },
  type: 'string' | 'int' | 'datetime'
): T | undefined | null {
  const { valueStr, valueInt } = attributeValue
  switch (type) {
    case 'string':
      return valueStr as T | undefined
    case 'int':
      return valueInt as T | undefined
    case 'datetime':
      return valueStr ? (new Date(valueStr) as unknown as T | undefined) : null
    default:
      return null
  }
}

export function* cartesian<T>(...args: Array<Array<T>>) {
  const [head, ...tail] = args
  const remainder = tail.length ? cartesian(...tail) : [[]]
  for (const r of remainder) {
    for (const h of head) {
      yield [h, ...r]
    }
  }
}

export const getCookieValue = (name: string, cookie?: string) =>
  cookie?.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''

export const logout = () => {
  mixpanel.reset()
  if (process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID) {
    FullStory('setIdentity', { anonymous: true })
  }
  void signOut({
    callbackUrl: '/',
  })
}

// https://github.com/nextauthjs/next-auth/issues/596#issuecomment-943453568
export const reloadSession = () => {
  const event = new Event('visibilitychange')
  document.dispatchEvent(event)
}

export const getRoute = <K extends RouteKeyType>(
  route: K,
  ...parameters: Parameters<(typeof RoutesFunc)[K]>
) => {
  const clb: (...parameters) => string = RoutesFunc[route]
  return `/${clb(...parameters)}`
}

export const countriesOptions = Object.entries(countries).map(([value, { name }]) => ({
  value,
  label: name,
}))

export const timezoneOptions = Intl.supportedValuesOf('timeZone').map((item) => ({
  label: item.replaceAll('_', ' '),
  value: item,
}))

export const getCountryFromCode = (countryCode: string) =>
  countriesOptions.find(({ value }) => value === countryCode)

export const downloadFileFromUrl = ({ url, filename }: { url: string; filename: string }) => {
  const a = document.createElement('a')
  a.download = filename
  a.target = '_blank'
  a.href = url
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const range = (start, stop, step = 1) => {
  if (stop === undefined) {
    ;[start, stop] = [0, start]
  }

  start -= step
  return {
    [Symbol.iterator]: () => ({
      next: () => ({
        value: (start += step),
        done: start >= stop,
      }),
    }),
  }
}

/**
 * Generates a faux word with a specified number of letters.
 *
 * A faux word is a randomly generated combination of consonants and vowels.
 *
 * @param {number} lettersCount - The desired length of the faux word.
 * @returns {string} A faux word of the specified length.
 */
export const generateFauxWord = (lettersCount = 5) => {
  // Define arrays of consonants and vowels
  // (No 'q' to make it easier to remember)
  const consonants: string[] = [
    'b',
    'c',
    'd',
    'f',
    'g',
    'h',
    'j',
    'k',
    'l',
    'm',
    'n',
    'p',
    'r',
    's',
    't',
    'v',
    'w',
    'x',
    'y',
    'z',
  ]
  const vowels: string[] = ['a', 'e', 'i', 'o', 'u']

  // Create an array of the desired length and map it to generate the faux word
  const fauxWordArray: string[] = Array.from({ length: lettersCount }, (_, index) => {
    const isConsonant = index % 2 === 0 // Alternate between consonants and vowels
    const sourceArray = isConsonant ? consonants : vowels
    return sourceArray[Math.floor(Math.random() * sourceArray.length)]
  })

  // Join the array elements to form the faux word
  const fauxWord: string = fauxWordArray.join('')

  return fauxWord
}

/**
 * Replaces newlines `\n` with `<br>` elements.
 * @param {string} text - The text to replace newlines in.
 * @returns {React.ReactNode} The text with newlines replaced with `<br>` elements.
 */
export const replaceNewlinesWithBreaks = (text: string) =>
  text
    .split('\n')
    .map((text, index, length) =>
      React.createElement(
        React.Fragment,
        { key: index },
        text,
        index !== length.length - 1 && React.createElement('br')
      )
    ) as React.ReactNode

export const notEmptyFilter = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined

export const getT = (locale: string, namespace: string) => {
  global.i18nConfig = i18nConfig
  if (global?.__NEXT_TRANSLATE__?.config) {
    if (!global.__NEXT_TRANSLATE__.config.loadLocaleFrom) {
      global.__NEXT_TRANSLATE__.config.loadLocaleFrom = i18nConfig.loadLocaleFrom
    }
  }
  return getTranslationFn(locale, namespace)
}

/**
 * returns language code in format sk, en, fr,...
 * @param locale locale in format (en, en-US (ISO 639-1 from request))
 * @returns locale in format sk, en, fr, or default en
 */
export const getUserLocale = (locale?: string | null) => locale?.toLowerCase().split('-')[0] ?? 'sk'

export const getLocaleDateTimeStr = (date: Date, locale: string, timezone: string) =>
  format(toZonedTime(date, timezone), 'dd. MM. HH:mm')
export const getLocaleTimeStr = (date: Date, locale: string, timezone: string) =>
  format(toZonedTime(date, timezone), 'HH:mm')

export async function getIsBrave() {
  return (navigator.brave && (await navigator.brave.isBrave())) || false
}

export const getIsLoggedIn = (session: { user?: { isAnonymous: boolean } | null } | null) =>
  Boolean(session?.user && !session.user.isAnonymous)

export const isMerchant = (session: { user?: { profile: object } | null } | null) =>
  Boolean(session?.user?.profile)

/**
 * Finds link in string and returns link with https prefix
 * @param str
 * @returns
 */
export const findLinkInString = (str: string) => {
  const match = str.match(
    /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]{2,}\.[a-zA-Z]{2,}(?:[\/\?][^\s]*)?(?=\s|$)/g
  )
  let link = match?.[0]
  if (!link) {
    return null
  }

  if (link.search(/^http[s]?\:\/\//) == -1) {
    link = 'https://' + link
  }

  return link
}

export const getFullName = (
  contactInfo: {
    firstName?: string | null | undefined
    lastName?: string | null | undefined
  } | null = {}
) => {
  if (!contactInfo) {
    return null
  }

  const { firstName, lastName } = contactInfo
  return firstName || lastName
    ? [firstName, lastName].reduce((acc, curr) => (curr ? `${acc} ${curr}` : acc), '')
    : null
}

export const getProjectDomain = () => process.env.NEXT_PUBLIC_APP_URL.replace('https://', '')

/**
 * Returns a string with ellipsis in the middle of the string.
 * @param str The string to shorten.
 * @param maxLength The maximum length of the string.
 */
export const ellipsisInMiddle = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str
  }
  const half = Math.floor(maxLength / 2)
  return `${str.slice(0, half)}...${str.slice(-half)}`
}
