import React, { useMemo } from 'react'
import { GetServerSidePropsContext } from 'next'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ApolloProvider as Provider,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import uniq from 'lodash/uniq'
import merge from 'lodash/merge'
import { GqlErrorCode } from '../constants'

export interface IErrors {
  graphQlErrors: { [key in keyof typeof GqlErrorCode]: string }
  networkError: string
}

export const ERRORS: IErrors = {
  graphQlErrors: {
    AccountPasswordTooWeak: 'Password is too weak.',
    BookingAlreadyInCart: 'Booking is already in cart',
    BookingOverlap: 'Desired booking time overlaps with a booked date already',
    BookingCapacityReached: 'Booking capacity for this slot is full',
    MissingCustomerEmail: 'Customer email address is missing',
    TierNotFound: 'Tier not found.',
    LoginToSubscribe: 'Login to manage subscriptions to this profile.',
    InternalSeverError: 'Internal server error.',
    Unauthorized: 'Unauthorized access',
    NotAuthorized: 'Unauthorized access',
    SignUpEmailExists: 'The email already exists',
    PaymentNotFound: 'The payment does not exist',
    NoUserProfile: 'User does not have profile yet.',
    UsernameExists: 'The username already exists',
    WebsiteExists: 'The website with that domain already exists',
    UpdateEmailTokenNotExist: 'The email token does not exist',
    NotFound: 'Content not found.',
    NoStripeConnectAccount: 'No Stripe account',
    ProfileNotFound: 'Profile was not found',
    ProfileCurrencyNotFound: 'Profile currency was not found',
    ProductAlreadyPublished: 'Product is already published',
    QuantityTooBig: 'Quantity exceeds stock',
    NoDeviceId: 'No device ID',
    PaymentLowAmount: 'Payment amount is too low',
    MissingOrderAddress: 'Order is missing address and cannot be processed',
    ProductNotPublishedCart: 'Product is not published yet',
    SameEmail: 'You can\'t change your email to the same one',
    CustomPaymentLowAmount: 'Payment amount is too low',
    OrderNotFree: 'Order is not free',
    CommunityPostDoesNotExist: 'Community post does not exist',
    OrderAlreadyPaid: 'Order is already paid for',
    WrongCredentials: 'Login failed. You have entered wrong credentials or an account with this email does not exist.',
    PasswordResetLinkExpired: 'Password reset link has expired',
    BookingSettingsNotSetup: 'Booking settings are not set up',
    BookingSlotSettingsNotFound: 'Booking slot settings not found',
    InvalidDateRange: 'Invalid date range',
    TimeSlotNotSpecified: 'Time slot not specified',
    ContactInfoNotFound: 'Contact info not found',
    InvalidAction: 'Invalid action',
    InvalidField: 'Invalid field',
    PaymentFailed: 'Payment failed',
    PaymentProcessedAlready: 'Payment has already been processed',
    VideoEncodingFailed: 'Video encoding failed',
  },
  networkError: 'Connection to server failed',
}

interface ApolloProviderProps {
  children: React.ReactNode
  onError?: (error: string) => void
  onUnauthError?: () => void
  initialApolloState: NormalizedCacheObject
}

let globalApolloClient: ApolloClient<NormalizedCacheObject>

function createApolloClient(
  { onError: handleError, onUnauthError }: Partial<ApolloProviderProps>,
  context?: GetServerSidePropsContext
) {
  const { headers } = context?.req || {}
  const authLink = new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      operation.setContext((context: { [key: string]: string }) => ({
        ...(context && {}),
        fetchOptions: {
          credentials: 'include',
        },
        headers,
      }))

      return response
    })
  )

  const httpLink = createHttpLink({
    uri: `${process.env.NEXT_PUBLIC_API_URL}/api/graphql`,
    credentials: 'same-origin',
    headers: {
      cookie: headers?.cookie ?? '',
    },
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      uniq(graphQLErrors.map(({ message }) => message)).map((message) => {
        const customMessage = ERRORS.graphQlErrors[message as keyof typeof ERRORS.graphQlErrors]
        if (customMessage) {
          handleError?.(customMessage)
        } else {
          console.error(message)
        }
      })

      const isUserUnauthorized = graphQLErrors.some(
        ({ message }) => message === ERRORS.graphQlErrors.Unauthorized
      )
      if (isUserUnauthorized) {
        onUnauthError?.()
      }
    }
    if (networkError) {
      handleError?.(ERRORS.networkError)
    }
  })
  const link = (() => {
    const sessionToken = undefined
    return ApolloLink.from([errorLink, authLink, httpLink])
    // if (sessionToken) {
    //   // https://hasura.io/learn/graphql/nextjs-fullstack-serverless/apollo-client/
    //   // new SubscriptionClient('wss://ready-panda-91.hasura.app/v1/graphql', {
    //   //   lazy: true,
    //   //   reconnect: true,
    //   //   connectionParams: async () => {
    //   //     await requestAccessToken() // happens on the client
    //   //     return {
    //   //       headers: {
    //   //         authorization: accessToken ? `Bearer ${accessToken}` : '',
    //   //       },
    //   //     }
    //   //   },
    //   // })
    //   const wsLink = new WebSocketLink({
    //     uri: process.env.NEXT_PUBLIC_APP_WS_URL,
    //     options: {
    //       reconnect: true,
    //       connectionParams: {
    //         sessionToken,
    //       },
    //     },
    //   })
    //   return split(
    //     ({ query }) => {
    //       const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode
    //       return kind === 'OperationDefinition' && operation === 'subscription'
    //     },
    //     wsLink,
    //     authLink.concat(httpLink)
    //   )
    // } else {
    //   return ApolloLink.from([errorLink, authLink, httpLink])
    // }
  })()

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: 'cache-and-network'
      }
    }
  })
}

export function initializeApollo(
  props: Partial<ApolloProviderProps> = {},
  context?: GetServerSidePropsContext
) {
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    const apolloClient = createApolloClient(props, context)
    if (props.initialApolloState) {
      apolloClient.cache.restore(props.initialApolloState)
    }
    return apolloClient
  }

  if (!globalApolloClient) {
    globalApolloClient = createApolloClient(props, context)
  }

  // gets hydrated here
  if (props.initialApolloState) {
    globalApolloClient.cache.restore(props.initialApolloState)
  }

  // Create the Apollo Client once in the client
  return globalApolloClient
}

export function useApollo(props: ApolloProviderProps) {
  return useMemo(() => {
    if (globalApolloClient && props.initialApolloState) {
      const before = globalApolloClient.cache.extract()
      const merged = merge({}, before, props.initialApolloState)
      globalApolloClient.restore(merged)
    }
    return globalApolloClient ?? initializeApollo(props)
  }, [props.initialApolloState])
}

export const ApolloProvider = ({
  children,
  onError,
  onUnauthError,
  initialApolloState,
}: ApolloProviderProps) => {
  const client = useApollo({
    children,
    onError,
    onUnauthError,
    initialApolloState,
  })
  return <Provider client={client}>{children}</Provider>
}
