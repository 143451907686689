import { chakra, Stack, useBreakpointValue } from '@chakra-ui/react'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import type { CheckoutModalPromiseAction, ModalPromise } from 'src/types/general'
import { getFadeGradient } from 'utils/theme/helpers'
import { Cart } from '../Cart'
import { MembershipCheckout } from '../MembershipCheckout'

interface Props {
  username?: string
  tierId?: string
  modalPromise?: ModalPromise<CheckoutModalPromiseAction>
  isLoading: boolean
  returnTo?: string
  onClose: () => void
}

export const CheckoutModal = ({
  username,
  tierId,
  modalPromise,
  returnTo,
  onClose,
}: Props) => {
  const { t } = useTranslation('profile')
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [profileName, setProfileName] = React.useState<string>('')
  const isOpen = !!modalPromise

  const handleClose = () => {
    modalPromise?.reject()
    onClose()
  }

  // const handleFormSubmit = ({ message }: Form) => {
  //   modalPromise?.resolve(message)
  // }
  return (
    <ModalOrDrawer
      title={t('CheckoutModal.SubscribeTo', { profileName })}
      maxW="container.sm"
      hasPx={!isMobile}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Stack pos="relative" spacing={4} pb={64}>
        {tierId ? (
          <MembershipCheckout
            tierId={tierId}
            returnTo={returnTo}
            onLoadTier={(tier) => {
              setProfileName(tier?.Product.Profile.name || '')
            }}
          />
        ) : (
          username && <Cart username={username} />
        )}
      </Stack>
      <chakra.div
        pos="fixed"
        pointerEvents="none"
        bottom={0}
        left={0}
        right={0}
        height={20}
        display={{ base: 'block', md: 'none' }}
        __css={{ bg: getFadeGradient(80, 180) }}
        // show until user scrolls to bottom of the modal
        opacity={1}
      />
    </ModalOrDrawer>
  )
}
