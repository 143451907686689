import React from 'react'
import Link from 'next/link'
import { format } from 'date-fns/format'
import { Text } from '@chakra-ui/react'
import { getFullName, getRoute } from 'utils/helpers'
import { getPrice } from 'utils/payments'
import { BackofficeOrdersQuery, OrderStatusEnum } from 'src/generated/graphql-frontend'
import useTranslation from 'next-translate/useTranslation'

export const OrderRow: React.FC<
  BackofficeOrdersQuery['backofficeOrders']['orders'][number] & {
    currency: string
    isFromDashboard?: boolean
  }
> = ({ id, createdAt, status, amount, Payment, ContactInfo, currency }) => {
  const { t } = useTranslation('backOffice')
  const date = status === OrderStatusEnum.Draft ? createdAt : Payment?.paidAt
  const orderDate = date ? format(new Date(date as string), 'dd. MM. yy') : '-'
  return (
    <>
      <Text
        // weird SSR fix
        as="div"
        fontWeight="bold"
        fontSize={{ base: 'sm', md: 'inherit' }}
        noOfLines={{ base: 3, lg: undefined }}
      >
        <Text>
          <Link href={`${getRoute('backofficeOrder', id)}?isFromDashboard`}>
            {getFullName(ContactInfo) || t('Shop.unknownUser')}
            {/* Show country */}
          </Link>
        </Text>
        <Text fontWeight="normal" pt="2" color="teal.200" display={{ base: 'block', xl: 'none' }}>
          {t(`order:${status}`)}
        </Text>
      </Text>
      <Text color="teal.200">{orderDate}</Text>
      <Text color="teal.200" display={{ base: 'none', xl: 'block' }}>
        {t(`order:${status}`)}
      </Text>
      <Text fontWeight="medium" fontSize={{ base: 'sm', md: 'inherit' }}>
        {getPrice(amount, currency)}
      </Text>
    </>
  )
}
